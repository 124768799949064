import { render, staticRenderFns } from "./FormsCard.vue?vue&type=template&id=36cb92f1&scoped=true&"
import script from "./FormsCard.vue?vue&type=script&lang=js&"
export * from "./FormsCard.vue?vue&type=script&lang=js&"
import style0 from "./FormsCard.vue?vue&type=style&index=0&id=36cb92f1&prod&lang=scss&scoped=true&"
import style1 from "./FormsCard.vue?vue&type=style&index=1&id=36cb92f1&prod&lang=scss&"
import style2 from "./FormsCard.vue?vue&type=style&index=2&id=36cb92f1&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "36cb92f1",
  null
  
)

export default component.exports
import QMenu from 'quasar/src/components/menu/QMenu.js';
import QChip from 'quasar/src/components/chip/QChip.js';
import QAvatar from 'quasar/src/components/avatar/QAvatar.js';
import QChatMessage from 'quasar/src/components/chat/QChatMessage.js';
import QStepper from 'quasar/src/components/stepper/QStepper.js';
import qInstall from "../../../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QMenu,QChip,QAvatar,QChatMessage,QStepper});
